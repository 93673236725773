.header_wrapper{
    position: fixed;
    top: 0;
    z-index: 999;
    background-color: rgba(244, 244, 242, 1);
    color: #484848;
    display: flex;
    align-items: center;
    width: 100vw;
    height: 3.75rem;
    border-bottom: 1px solid #C4C4C4;
    padding: 0 3rem;
    box-sizing: border-box;
    transition: all 0.2s ease;
}
.header_wrapper a{
    text-decoration: none;
    color: #484848;
}
.header_wrapper.hidden{
    background-color: rgba(244, 244, 242, 0.8);
    height: 3rem;
    transition: all 0.2s ease;
}
.header_logo{
    display: flex;
    align-items: center;
}
.header_menu{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
}
.logo{
    width: 3rem;
    margin-right: 0.75rem;
}
.menu_item{
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0 1rem;
}
.menu_item:hover{
    color: #CB9F86;
    transition: all 0.2s ease-out;
    cursor: pointer;
}
.btn_menu{
    display: none !important;
}

/* Mobile */
@media (max-width: 600px) {
    .header_wrapper{
        padding: 0 2rem;
    }
    .header_menu{
        position: absolute;
        top: 3.5rem;
        transform: translateX(-100%);
        transition: all 0.5s ease;
        width: 100vw;
        padding: 1rem 0;
        margin-left: -2rem;
        flex-direction: column;
        background-color: #D3C5BD;
    }
    .header_menu.active{
        transform: translateX(0);
        transition: all 0.5s ease;
    }
    .header_wrapper.hidden .header_menu{
        top: 3rem;
    }
    .menu_item{
        padding: 1.5rem 0;
    }
    .btn_menu{
        display: block !important;
        width: 2rem;
        margin-left: auto;
    }
}