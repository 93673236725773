.container{
    min-height: 50vh;
    padding: 2.5rem 0 0.625rem;
    box-sizing: border-box;
}
.list_wrapper a{
    text-decoration: none;
    color: #484848;
}
.list_wrapper{
    display: flex;
    width: 80vw;
    flex-wrap: wrap;
    justify-content: space-between;
}
.item_box{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 24vw;
    max-height: 50vh;
    cursor: pointer;
    margin-bottom: 2.25rem;
    border: 1.5px solid #C4C4C4;
    padding: 1rem 0 1.25rem;
    box-sizing: border-box;
    border-radius: 0.375rem;
}
.item_box:hover{
    border: 1.5px solid #CB9F86;
    color: #CB9F86;
    transform: scale(1.05, 1.05);
    transition: all 0.5s;
}
.item_text{
    font-size: 1.5rem;
    margin-top: 1rem;
}

/* Mobile */
@media (max-width: 600px) {
    .list_wrapper{
        width: 85vw;
    }
    .item_box{
        width: 40vw;
    }
    .item_text{
        font-size: 1rem;
    }
}