.btn{
    position: fixed;
    z-index: 999;
    right: 3.75rem;
    bottom: 3.125rem;
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 1rem;
    border: 1.5px solid #CB9F86;
    color: #CB9F86;
}
.btn:hover{
    cursor: pointer;
    background-color: #fff;
}

/* Mobile */
@media (max-width: 600px) {
    .btn{
        right: 1rem;
        bottom: 2rem;
    }
}