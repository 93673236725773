@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600&display=swap');

/* General */
*,
::before,
::after{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html{
  width: 100vw;
  height: 100vh;
  font-size: 16px;
}
body {
  background-color: #F4F4F2;
  color: #484848;
  text-align: center;
  font-family: 'Comfortaa';
}
a{
  text-decoration: none;
  color: #484848;
}
a:hover{
  color: #CB9F86;
}

/* Main */
.container{
  background-color: #F4F4F2;
  color: #484848;
  width: 100vw;
  max-width: 100%; /* for bootstrap*/
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3.5rem 0 2.5rem;
  box-sizing: border-box;
}