.page_title{
    font-size: 2.75rem;
    font-weight: 500;
    margin: 3.125rem 0 1.875rem;
}
.page_subtitle{
    font-size: 2rem;
    font-weight: 500;
    margin: 2.5rem 0 0.625rem;
    opacity: 0.6;
}