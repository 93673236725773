@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* General */
*,
::before,
::after{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html{
  width: 100vw;
  height: 100vh;
  font-size: 16px;
}
body {
  background-color: #F4F4F2;
  color: #484848;
  text-align: center;
  font-family: 'Comfortaa';
}
a{
  text-decoration: none;
  color: #484848;
}
a:hover{
  color: #CB9F86;
}

/* Main */
.container{
  background-color: #F4F4F2;
  color: #484848;
  width: 100vw;
  max-width: 100%; /* for bootstrap*/
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3.5rem 0 2.5rem;
  box-sizing: border-box;
}
.Header_header_wrapper__xoaDf{
    position: fixed;
    top: 0;
    z-index: 999;
    background-color: rgba(244, 244, 242, 1);
    color: #484848;
    display: flex;
    align-items: center;
    width: 100vw;
    height: 3.75rem;
    border-bottom: 1px solid #C4C4C4;
    padding: 0 3rem;
    box-sizing: border-box;
    transition: all 0.2s ease;
}
.Header_header_wrapper__xoaDf a{
    text-decoration: none;
    color: #484848;
}
.Header_header_wrapper__xoaDf.Header_hidden__2_USb{
    background-color: rgba(244, 244, 242, 0.8);
    height: 3rem;
    transition: all 0.2s ease;
}
.Header_header_logo__3ECFX{
    display: flex;
    align-items: center;
}
.Header_header_menu__1Xf6P{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
}
.Header_logo__3EvUy{
    width: 3rem;
    margin-right: 0.75rem;
}
.Header_menu_item__1fVEf{
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0 1rem;
}
.Header_menu_item__1fVEf:hover{
    color: #CB9F86;
    transition: all 0.2s ease-out;
    cursor: pointer;
}
.Header_btn_menu__3O8Zg{
    display: none !important;
}

/* Mobile */
@media (max-width: 600px) {
    .Header_header_wrapper__xoaDf{
        padding: 0 2rem;
    }
    .Header_header_menu__1Xf6P{
        position: absolute;
        top: 3.5rem;
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
        transition: all 0.5s ease;
        width: 100vw;
        padding: 1rem 0;
        margin-left: -2rem;
        flex-direction: column;
        background-color: #D3C5BD;
    }
    .Header_header_menu__1Xf6P.Header_active__11YnJ{
        -webkit-transform: translateX(0);
                transform: translateX(0);
        transition: all 0.5s ease;
    }
    .Header_header_wrapper__xoaDf.Header_hidden__2_USb .Header_header_menu__1Xf6P{
        top: 3rem;
    }
    .Header_menu_item__1fVEf{
        padding: 1.5rem 0;
    }
    .Header_btn_menu__3O8Zg{
        display: block !important;
        width: 2rem;
        margin-left: auto;
    }
}
.Footer_footer_wrapper__24qM8{
    background-color: #F4F4F2;
    color: #484848;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 7.5rem;
    border-top: 1px solid #C4C4C4;
    box-sizing: border-box;
    margin-top: auto;
    font-size: 1rem;
}
.Footer_footer_icon_wrapper__256hH{
    display: flex;
    margin-bottom: 0.75rem;
}
.Footer_footer_icon_box__-FuPs{
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: #484848;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 0.25rem;
}
.Footer_footer_icon__1KTYO{
    width: 2.25rem;
}
.BackToTop_btn__6d14k{
    position: fixed;
    z-index: 999;
    right: 3.75rem;
    bottom: 3.125rem;
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 1rem;
    border: 1.5px solid #CB9F86;
    color: #CB9F86;
}
.BackToTop_btn__6d14k:hover{
    cursor: pointer;
    background-color: #fff;
}

/* Mobile */
@media (max-width: 600px) {
    .BackToTop_btn__6d14k{
        right: 1rem;
        bottom: 2rem;
    }
}
.Loading_container__1aMyo{
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Comfortaa';
    background-color: rgba(244, 244, 242, 0.8);
    color: #484848;
}
.Loading_animation__1iGhr{
    width: 18.75rem;
    height: auto;
}
.Loading_text__2sRFN{
    font-size: 1.25rem;
    font-weight: 600;
    margin-top: -0.625rem;
}
.YoutubeEmbed_video__1nBMy{
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}
  
.YoutubeEmbed_video__1nBMy iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}
.Breadcrumb_breadcrumb_wrapper__2j9-U{
    color: rgba(72, 72, 72, 0.6);
    width: 80vw;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin: 0.5rem 0;
}
.Breadcrumb_breadcrumb_wrapper__2j9-U a{
    text-decoration: none;
    color: rgba(72, 72, 72, 0.6);
}
.Breadcrumb_breadcrumb_wrapper__2j9-U a:hover{
    color: #CB9F86;
    cursor: pointer;
}
.Breadcrumb_item_box__3pUhO{
    display: flex;
}
.Breadcrumb_breadcrumb_item__3NHwX{
    font-size: 1rem;
    font-weight: 500;
    margin: 0 0.25rem;
}
.Model_container__3WKlx{
    min-height: 50vh;
    padding: 2.5rem 0 0.625rem;
    box-sizing: border-box;
}
.Model_list_wrapper__1Z8az a{
    text-decoration: none;
    color: #484848;
}
.Model_list_wrapper__1Z8az{
    display: flex;
    width: 80vw;
    flex-wrap: wrap;
    justify-content: space-between;
}
.Model_item_box__3C-zx{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 24vw;
    max-height: 50vh;
    cursor: pointer;
    margin-bottom: 2.25rem;
    border: 1.5px solid #C4C4C4;
    padding: 1rem 0 1.25rem;
    box-sizing: border-box;
    border-radius: 0.375rem;
}
.Model_item_box__3C-zx:hover{
    border: 1.5px solid #CB9F86;
    color: #CB9F86;
    -webkit-transform: scale(1.05, 1.05);
            transform: scale(1.05, 1.05);
    transition: all 0.5s;
}
.Model_item_text__Q6iCk{
    font-size: 1.5rem;
    margin-top: 1rem;
}

/* Mobile */
@media (max-width: 600px) {
    .Model_list_wrapper__1Z8az{
        width: 85vw;
    }
    .Model_item_box__3C-zx{
        width: 40vw;
    }
    .Model_item_text__Q6iCk{
        font-size: 1rem;
    }
}
/* Main */
.HomePage_page_title__3MdSN{
    font-size: 2.75rem;
    font-weight: 500;
    margin: 3.125rem 0 3.125rem;
}
.HomePage_page_subtitle__786Y1{
    font-size: 2rem;
    font-weight: 500;
    margin: 2.5rem 0 0.625rem;
}
.HomePage_block_wrapper__Zp6HM{
    width: 100vw;
    height: 100vh;
    z-index: 1;
}
.HomePage_block_wrapper__Zp6HM a{
    text-decoration: none;
    color: #484848;
}
.HomePage_content_wrapper__268PK{
    display: flex;
    justify-content: space-around;
    padding-top: 3rem;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}
.HomePage_logo_name__EH6nt{
    font-size: 7.5rem;
    margin-top: calc((100vh - 10.625rem)/2);
}
.HomePage_blog__2A8xe{
    width: 70%;
}
.HomePage_blog_box__2XPHz{
    width: 20vw;
    height: 60vh;
    background-color: #C4C4C4;
    color: #fff;
    border-radius: 1.25rem;
    overflow: hidden;
    font-size: 6.25rem;
    line-height: 65vh;
}
.HomePage_youtube__hBWUd{
    width: 85%;
}
.HomePage_youtube_info_wrapper__2wEo6{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.HomePage_youtube_avatar_box__1NRuD{
    width: 7.5rem;
    height: 7.5rem;
    border-radius: 50%;
    overflow: hidden;
    background-color: #fff;
    margin-bottom: 1.875rem;
}
.HomePage_youtube_avatar_box__1NRuD:hover{
    /* Add some animation */
}
.HomePage_youtube_avatar__2XG4F{
    width: 100%;
    height: 100%;
}
.HomePage_youtube_name__1KlS4{
    font-size: 1.5rem;
    font-weight: 500;
    margin: 1rem 0;
}
.HomePage_youtube_video__1wsV0{
    width: 45vw;
    height: auto;
}
.HomePage_btn__19xsQ{
    font-size: 1.125rem;
    font-weight: 500;
    border: 1.5px solid #C4C4C4;
    border-radius: 0.375rem;
    padding: 0.375rem 1.125rem;
}
.HomePage_btn__19xsQ:hover{
    cursor: pointer;
    color: #CB9F86;
    border: 1.5px solid #CB9F86;
    -webkit-transform: scale(1.05, 1.05);
            transform: scale(1.05, 1.05);
    transition: all 0.5s;
}
.HomePage_carousel__2U2fN{
    width: 50vw;
    height: 27vw;
    margin: auto;
    margin-bottom: 2.5rem;
    background-color: #fff;
}
.HomePage_slide_box__9Yuzy{
    width: 50vw;
    height: 27vw;
}
.HomePage_slide_img__18i0L{
    width: 100%;
    height: 100%;
    object-position: top center;
    object-fit: cover;
}
.HomePage_dot_box__1Ku3K{
    position: fixed;
    right: 1.25rem;
    top: calc(100vh / 2);
}
.HomePage_dot__21L5G{
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 50%;
    border: 1.5px solid transparent;
    margin: 0.625rem 0;
}

/* Mobile */
@media (max-width: 600px) {
    .HomePage_logo_name__EH6nt{
        font-size: 4rem;
    }
    .HomePage_blog__2A8xe{
        display: flex;
        flex-direction: column;
    }
    .HomePage_blog_box__2XPHz{
        width: 80vw;
        height: 20vh;
        line-height: 20vh;
        margin: 0.75rem 0;
    }
    .HomePage_youtube__hBWUd{
        display: flex;
        flex-direction: column;
    }
    .HomePage_youtube_video__1wsV0{
        width: 80vw;
        height: auto;
        margin-top: 2.5rem;
    }
    .HomePage_carousel__2U2fN{
        width: 80vw;
        height: 42vw;
    }
    .HomePage_slide_box__9Yuzy{
        width: 80vw;
        height: 42vw;
    }
}
.BlogPage_page_title__sSpAa{
    font-size: 2.75rem;
    font-weight: 500;
    margin: 3.125rem 0 1.875rem;
}
.BlogPage_page_subtitle__2VJ91{
    font-size: 2rem;
    font-weight: 500;
    margin: 2.5rem 0 0.625rem;
    opacity: 0.6;
}
.AboutPage_page_title__2bXeJ{
    font-size: 2.75rem;
    font-weight: 500;
    margin: 3.125rem 0 1.875rem;
}
.AboutPage_page_subtitle__3NHto{
    font-size: 2rem;
    font-weight: 500;
    margin: 2.5rem 0 0.625rem;
    opacity: 0.6;
}
.SigninPage_page_title__1yXxw{
    font-size: 2.75rem;
    font-weight: 500;
    margin: 3.125rem 0 1.875rem;
}
.SigninPage_page_subtitle__2qEIp{
    font-size: 2rem;
    font-weight: 500;
    margin: 2.5rem 0 0.625rem;
    opacity: 0.6;
}
.SigninPage_input_wrapper__2Jwk5 input{
    display: block;
    outline-style: none;
    border: 1px solid #fff;
    border-radius: 0.375rem;
    width: 18.75rem;
    padding: 0.75rem;
    font-size: 1.25rem;
    color: #484848;
    margin: 1rem 0;
}
.SigninPage_input_wrapper__2Jwk5 input:focus{
    border: 1px solid #CB9F86;
}
.SigninPage_input_wrapper__2Jwk5 input::-webkit-input-placeholder{
    color: #484848;
    opacity: 0.3;
}
.SigninPage_input_wrapper__2Jwk5 input:-ms-input-placeholder{
    color: #484848;
    opacity: 0.3;
}
.SigninPage_input_wrapper__2Jwk5 input::placeholder{
    color: #484848;
    opacity: 0.3;
}
.SigninPage_input_wrapper__2Jwk5 button{
    outline-style: none;
    border: 1.5px solid #C4C4C4;
    border-radius: 0.375rem;
    padding: 0.375rem 1.125rem;
    font-size: 1.125rem;
    font-weight: 500;
    color: #484848;
    background-color: #F4F4F2;
    margin-top: 5rem;
}
.SigninPage_input_wrapper__2Jwk5 button:hover{
    cursor: pointer;
    color: #CB9F86;
    border: 1.5px solid #CB9F86;
    -webkit-transform: scale(1.05, 1.05);
            transform: scale(1.05, 1.05);
    transition: all 0.5s;
}
