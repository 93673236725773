.container{
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Comfortaa';
    background-color: rgba(244, 244, 242, 0.8);
    color: #484848;
}
.animation{
    width: 18.75rem;
    height: auto;
}
.text{
    font-size: 1.25rem;
    font-weight: 600;
    margin-top: -0.625rem;
}