.footer_wrapper{
    background-color: #F4F4F2;
    color: #484848;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 7.5rem;
    border-top: 1px solid #C4C4C4;
    box-sizing: border-box;
    margin-top: auto;
    font-size: 1rem;
}
.footer_icon_wrapper{
    display: flex;
    margin-bottom: 0.75rem;
}
.footer_icon_box{
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: #484848;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 0.25rem;
}
.footer_icon{
    width: 2.25rem;
}