.breadcrumb_wrapper{
    color: rgba(72, 72, 72, 0.6);
    width: 80vw;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin: 0.5rem 0;
}
.breadcrumb_wrapper a{
    text-decoration: none;
    color: rgba(72, 72, 72, 0.6);
}
.breadcrumb_wrapper a:hover{
    color: #CB9F86;
    cursor: pointer;
}
.item_box{
    display: flex;
}
.breadcrumb_item{
    font-size: 1rem;
    font-weight: 500;
    margin: 0 0.25rem;
}