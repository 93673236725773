.page_title{
    font-size: 2.75rem;
    font-weight: 500;
    margin: 3.125rem 0 1.875rem;
}
.page_subtitle{
    font-size: 2rem;
    font-weight: 500;
    margin: 2.5rem 0 0.625rem;
    opacity: 0.6;
}
.input_wrapper input{
    display: block;
    outline-style: none;
    border: 1px solid #fff;
    border-radius: 0.375rem;
    width: 18.75rem;
    padding: 0.75rem;
    font-size: 1.25rem;
    color: #484848;
    margin: 1rem 0;
}
.input_wrapper input:focus{
    border: 1px solid #CB9F86;
}
.input_wrapper input::placeholder{
    color: #484848;
    opacity: 0.3;
}
.input_wrapper button{
    outline-style: none;
    border: 1.5px solid #C4C4C4;
    border-radius: 0.375rem;
    padding: 0.375rem 1.125rem;
    font-size: 1.125rem;
    font-weight: 500;
    color: #484848;
    background-color: #F4F4F2;
    margin-top: 5rem;
}
.input_wrapper button:hover{
    cursor: pointer;
    color: #CB9F86;
    border: 1.5px solid #CB9F86;
    transform: scale(1.05, 1.05);
    transition: all 0.5s;
}