/* Main */
.page_title{
    font-size: 2.75rem;
    font-weight: 500;
    margin: 3.125rem 0 3.125rem;
}
.page_subtitle{
    font-size: 2rem;
    font-weight: 500;
    margin: 2.5rem 0 0.625rem;
}
.block_wrapper{
    width: 100vw;
    height: 100vh;
    z-index: 1;
}
.block_wrapper a{
    text-decoration: none;
    color: #484848;
}
.content_wrapper{
    display: flex;
    justify-content: space-around;
    padding-top: 3rem;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}
.logo_name{
    font-size: 7.5rem;
    margin-top: calc((100vh - 10.625rem)/2);
}
.blog{
    width: 70%;
}
.blog_box{
    width: 20vw;
    height: 60vh;
    background-color: #C4C4C4;
    color: #fff;
    border-radius: 1.25rem;
    overflow: hidden;
    font-size: 6.25rem;
    line-height: 65vh;
}
.youtube{
    width: 85%;
}
.youtube_info_wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.youtube_avatar_box{
    width: 7.5rem;
    height: 7.5rem;
    border-radius: 50%;
    overflow: hidden;
    background-color: #fff;
    margin-bottom: 1.875rem;
}
.youtube_avatar_box:hover{
    /* Add some animation */
}
.youtube_avatar{
    width: 100%;
    height: 100%;
}
.youtube_name{
    font-size: 1.5rem;
    font-weight: 500;
    margin: 1rem 0;
}
.youtube_video{
    width: 45vw;
    height: auto;
}
.btn{
    font-size: 1.125rem;
    font-weight: 500;
    border: 1.5px solid #C4C4C4;
    border-radius: 0.375rem;
    padding: 0.375rem 1.125rem;
}
.btn:hover{
    cursor: pointer;
    color: #CB9F86;
    border: 1.5px solid #CB9F86;
    transform: scale(1.05, 1.05);
    transition: all 0.5s;
}
.carousel{
    width: 50vw;
    height: 27vw;
    margin: auto;
    margin-bottom: 2.5rem;
    background-color: #fff;
}
.slide_box{
    width: 50vw;
    height: 27vw;
}
.slide_img{
    width: 100%;
    height: 100%;
    object-position: top center;
    object-fit: cover;
}
.dot_box{
    position: fixed;
    right: 1.25rem;
    top: calc(100vh / 2);
}
.dot{
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 50%;
    border: 1.5px solid transparent;
    margin: 0.625rem 0;
}

/* Mobile */
@media (max-width: 600px) {
    .logo_name{
        font-size: 4rem;
    }
    .blog{
        display: flex;
        flex-direction: column;
    }
    .blog_box{
        width: 80vw;
        height: 20vh;
        line-height: 20vh;
        margin: 0.75rem 0;
    }
    .youtube{
        display: flex;
        flex-direction: column;
    }
    .youtube_video{
        width: 80vw;
        height: auto;
        margin-top: 2.5rem;
    }
    .carousel{
        width: 80vw;
        height: 42vw;
    }
    .slide_box{
        width: 80vw;
        height: 42vw;
    }
}